<template>
  <main>
    <div class="container-bokahem">
      <div class="calendar-bokahem mb-4">
        <div class="calendar-bokahem-body">
          <div>
            <SellerLogo></SellerLogo>

            <div class="row-bokahem" v-if="success == false">

              <div class="col-bokahem-12 mt-1">

                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0">
                    Presentkort och fakturainformation
                  </legend>

                  <div class="bokahem-field-name-block mt-1">
                    <span style="padding-right: 5px;">Inlösenkod</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.giftcard_code.required">
                      krävs
                    </span>

                    <div style="display: inline-block" class="smartapresentkort-valid-error pr-1" v-if="giftcardMessageError">
                        {{ giftcardMessageError }}
                    </div>

                    <div style="display: inline-block" class="smartapresentkort-valid-success pr-1" v-if="giftcardMessageSuccess">
                        {{ giftcardMessageSuccess }}
                    </div>

                    <div style="display: inline-block" class="smartapresentkort-valid-error pr-1" v-if="giftcardTestShort !== null">
                      {{ giftcardTestShort }}
                    </div>

                    <span style="font-size:11px;font-weight: normal; display: inline-block;float:right;">på ditt presentkort</span>
                  </div>

                  <div class="info-input-bokahem-holder mb-1">
                    <input v-model="activation.giftcard_code" type="text" placeholder="Inlösenkod" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>

                  <div class="bokahem-field-name-block mt-1" v-if="isSuperSeller == 1">
                    <span style="padding-right: 5px;">Företag</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.supplier_id.required">
                      krävs
                    </span>
                  </div>

                  <div class="info-input-bokahem-holder mb-1" v-if="isSuperSeller == 1">
                    <select class="info-block-bokahem-input
                      select-option-bokahem 
                      border-rounded-bokahem 
                      font-weight-medium-bokahem"
                      @change="selectSupplier">
                      <option value="" disabled selected>Välj...</option>
                      <option 
                        v-for="(supplier, supplierKey) in suppliers"
                        :key="supplierKey"
                        :value="supplier.xfarp"
                        :selected="supplier.xfarp === activation.supplier_id">
                        {{ supplier.name }}
                      </option>
                    </select>
                  </div>

                  <div class="bokahem-field-name-block mt-1" v-if="rutRot == true">
                    <span>Personnummer</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.personal_number.required">
                      krävs
                    </span>

                    <span class="smartapresentkort-valid-error" v-if="personalNumberCheck === false">
                      inte giltig
                    </span>

                    <span style="font-size:11px;font-weight: normal; display: inline-block;float:right;">för att ta del av rutavdrag</span>
                  </div>

                  <div class="info-input-bokahem-holder mb-1" v-if="rutRot == true">
                    <input v-model="activation.personal_number" v-on:blur="personalNumberCheckMethod()" type="text" placeholder="Personnummer" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>
              
                  <div v-if="invoiceNumberType == invoiceNumberTypeOCR">
                    <div class="bokahem-field-name-block mt-1" ref="ocr_number">
                      <span>OCR-nummer</span>
                      
                      <span class="smartapresentkort-valid-error" v-if="submitted && ocrNumberCheck === null && !$v.activation.ocr_number.required">
                      krävs
                    </span>

                      <span v-bind:style="{color: validationColor}" class="smartapresentkort-valid-error" v-if="ocrNumberCheck === false">
                      inte giltig
                      </span>

                      <span style="font-size:11px;padding-top:2px;font-weight: normal; display: inline-block;float:right;">på fakturan som du vill betala med presentkort</span>

                    </div>

                    <div class="info-input-bokahem-holder mb-1" >
                      <input v-model="activation.ocr_number" v-on:blur="ocrNumberCheckMethod()" type="text" placeholder="OCR-nummer" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                    </div>
                  </div>

                  <div v-if="invoiceNumberType == invoiceNumberTypeNumber">
                    <div class="bokahem-field-name-block mt-1">
                      <span>Fakturanummer</span>
                      <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.invoice_number.required">
                      krävs
                    </span>
                      <span style="font-size:11px;padding-top:2px;font-weight: normal; display: inline-block;float:right;">på fakturan som du vill betala med presentkort</span>
                    </div>

                    <div class="info-input-bokahem-holder mb-1" >
                      <input v-model="activation.invoice_number" type="text" placeholder="Fakturanummer" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                    </div>
                  </div>

                  <div class="bokahem-field-name-block mt-1">
                    <span>Fakturans "Att betala" belopp</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.amount.required">
                      krävs
                    </span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.amount.numeric">
                      ska vara numeriskt
                    </span>

                    <span style="font-size:11px;font-weight: normal; padding-top:2px; display: inline-block;float:right;">så räknar vi ut om det är något kvar att betala </span>
                  </div>

                  <div class="info-input-bokahem-holder mb-1" >
                    <input v-model="activation.amount" type="text" placeholder='Fakturans "Att betala" belopp' class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>

                  <div class="m-auto-bokahem mt-2 mb-2 text-center-bokahem smartapresentkort-valid-success" v-if="activation.amount > 0
                  && activation.giftcard_code && giftcardMessageSuccess && (activation.invoice_number.length > 1 || activation.ocr_number.length > 1)">
                    Vi kommer skicka en betalning på {{ giftcardCalculation.activation_amount }}
                    kr för OCRnr: <span v-if="activation.invoice_number">{{ activation.invoice_number }}</span><span v-if="activation.ocr_number">{{ activation.ocr_number }}</span> till vårt bankgironr {{ bankNumber }}.
                  </div>

                </fieldset>
              </div>

              <div class="col-bokahem-12">
                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0">
                    Kontaktinformation
                  </legend>
                  <div class="m-auto-bokahem mt-1 mb-1 text-center-bokahem" >
                    Ange ditt namn och din e-post dit bekräftelsen ska skickas
                  </div>

                  <div class="bokahem-field-name-block mt-1">
                    <span>Förnamn</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.first_name.required">
                      krävs
                    </span>
                  </div>

                  <div class="info-input-bokahem-holder">
                    <input v-model="activation.first_name" type="text" placeholder="Förnamn" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>

                  <div class="bokahem-field-name-block mt-1">
                    <span>Efternamn</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.last_name.required">
                      krävs
                    </span>
                  </div>

                  <div class="info-input-bokahem-holder">
                    <input v-model="activation.last_name" type="text" placeholder="Efternamn" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>

                  <div class="bokahem-field-name-block mt-1">
                    <span>E-post</span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.email.required">
                      krävs
                    </span>
                    <span class="smartapresentkort-valid-error" v-if="submitted && !$v.activation.email.email">
                      är inte giltig
                    </span>
                  </div>

                  <div class="info-input-bokahem-holder mb-1">
                    <input v-model="activation.email" type="text" placeholder="E-post" class="info-block-bokahem-input border-rounded-bokahem font-weight-medium-bokahem">
                  </div>

                </fieldset>

              </div>

              <div class="col-bokahem-12">
                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0">
                    Inlösen och villkor

                    <span class="smartapresentkort-valid-error" v-if="submitted && agreed === false">
                      bör godkännas
                    </span>
                  </legend>

                  <div class="checkbox-label-row-bokahem mt-1 mb-1">
                  <label class="common-checkbox-label-bokahem" :class="{'common-checkbox-label-bokahem_active': agreed === true}">
                    <input type="checkbox" id="deal" class="common-checkbox">
                    <span class="common-custom-checkbox" v-bind:style="{ borderColor: checkboxBorderColor + ' !important'}"  @click="setApproveAgreement()">
                        <span class="common-custom-checkbox_after"></span>
                      </span>
                  </label>
                  <label for="deal" class="deal-label-text checkbox-label_col-bokahemor font-weight-medium-bokahem">
                    Jag godkänner <span class="text-underline" @click="showAgreementModal()">villkoren</span>
                  </label>
                </div>
                </fieldset>
              </div>

              <div class="col-bokahem-12 mt-1">
              
                <button v-show="activatingProgress == false"
                    v-bind:style="{ backgroundColor: buttonColor + ' !important', color: buttonTextColor + ' !important'}"
                    @mouseover="hover = true"
                    @mouseleave="hover = false"
                    :class="{ 'btn-hover': hover }"
                    @click="sendForm()" class="btn-bokahem btn-bokahem-primary text-button-color text-white border-rounded-bokahem btn-bokahem-lg font-weight-semi-bokahem">
                  Lös in presentkort
                </button>

                <button style="opacity: 0.6" v-show="activatingProgress == true"
                    v-bind:style="{ backgroundColor: buttonColor + ' !important', color: buttonTextColor + ' !important'}"
                    :class="{ 'btn-hover': hover }"
                     class="btn-bokahem btn-bokahem-primary text-button-color text-white border-rounded-bokahem btn-bokahem-lg font-weight-semi-bokahem">
                     Vänta en sekund
                </button>

              </div>
            </div>

            <div class="row-bokahem" v-if="success == true">
              <div class="col-bokahem-12 mt-1">
                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0">
                    Presentkort
                  </legend>
                  <div class="m-auto-bokahem mt-1 text-center-bokahem">
                    Ditt presentkort är giltigt och har ett värde på {{ giftcardActivation.giftcard_left_amount_value }}  kr.
                  </div>
                  <div class="m-auto-bokahem text-center-bokahem">
                    {{ giftcardActivation.activation_amount_value }}  kr har använts till att betala nedan faktura.
                  </div>
                  <div class="m-auto-bokahem text-center-bokahem mb-1">
                     {{ giftcardActivation.giftcard_new_left_amount_value }} kr finns kvar på presentkortet.
                  </div>
                </fieldset>

                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0 mt-1">
                    Faktura
                  </legend>

                  <div v-if="giftcardTest !== null" style="color: red" class="m-auto-bokahem mt-1 mb-1 text-center-bokahem" >
                    {{ giftcardTest }}
                  </div>

                  <div class="m-auto-bokahem mt-1 mb-1 text-center-bokahem" >
                    Vi har skickat en betalning pa {{ giftcardActivation.activation_amount_value }} kr till din faktura {{ activation.invoice_number }}
                    och har uppdaterat den enligt nedan.
                  </div>

              
                  <div class="m-auto-bokahem text-center-bokahem" v-if="supplierName">
                    <span class="font-weight-medium-bokahem">Företag:</span> <span style="float:right;">{{ supplierName }}</span>
                  </div>
  
                  <div class="m-auto-bokahem text-center-bokahem">
                   <span class="font-weight-medium-bokahem">Belopp att betala:</span> <span style="float:right;"> {{ giftcardActivation.total_invoice_amount_value }} kr</span>
                  </div>

                  <div class="m-auto-bokahem text-center-bokahem">
                    <span class="font-weight-medium-bokahem">Presentkort:</span> <span style="float: right;"> - {{ giftcardActivation.activation_amount_value }} kr</span>
                  </div>

                  <div class="m-auto-bokahem text-center-bokahem">
                    <span class="font-weight-medium-bokahem">Återstående belopp:</span> <span style="float: right">{{ giftcardActivation.invoice_amount_left_to_pay_value }} kr</span>
                  </div>



                  <div class="m-auto-bokahem text-center-bokahem mt-1 mb-1"
                       v-if="giftcardActivation.invoice_amount_left_to_pay == 0">
                    Tack för din betalning!
                  </div>

                  <div class="m-auto-bokahem text-center-bokahem mt-1 mb-1"
                       v-if="giftcardActivation.invoice_amount_left_to_pay > 0">
                    Var vänlig betala återstående belopp.
                  </div>

                  <div v-if="giftcardActivation.invoice_amount_left_to_pay > 0">
                    <div class="m-auto-bokahem text-center-bokahem mt-1">
                      <span class="font-weight-medium-bokahem">
                        BGnr:
                      </span>
                      <span>{{ bankNumber }}</span>
                    </div>
                    <div class="m-auto-bokahem text-center-bokahem mb-1" v-if="activation.ocr_number">
                      <span class="font-weight-medium-bokahem">
                        OCRnr:
                      </span>
                      <span>
                        {{ activation.ocr_number }}
                      </span>
                    </div>
                    <div class="m-auto-bokahem text-center-bokahem mb-1" v-if="activation.invoice_number">
                    <span class="font-weight-medium-bokahem">
                        Fakturanummer:
                      </span>
                      <span>
                        {{ activation.invoice_number }}
                      </span>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group-bokahem check_border_bokahem">
                  <legend class="font-weight-medium-bokahem mb-0 mt-1">
                      Bekräftelse
                  </legend>
                  <div class="m-auto-bokahem mt-1 mb-1 text-center-bokahem" >
                      En bekräftelse har skickats till {{ activation.email }}
                  </div>
                </fieldset>
              </div>
            </div>
            <SpkLogo></SpkLogo>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import {
  CREATE_GIFTCARD_ACTIVATION,
  FETCH_GIFTCARD_BY_CODE,
  FETCH_XDVA,
  MAKE_GIFTCARD_CALCULATION,
  CHECK_PERSONAL_NUMBER,
  CHECK_OCR_NUMBER,
} from "@/store/actions.type";

import {
  SET_GIFTCARD_ERROR,
  SET_ACTIVATING_PROGRESS,
  RESET_OCR_NUMBER_CHECK
} from "@/store/mutations.type";

import { required, email, numeric, requiredIf } from 'vuelidate/lib/validators';
import agreement from "@/pages/GiftcardActivateForm/components/agreement";
import {INVOICE_NUMBER_TYPE_NUMBER, INVOICE_NUMBER_TYPE_OCR } from "@/store/constants.type";
import SellerLogo from "@/pages/GiftcardActivateForm/components/SellerLogo";
import SpkLogo from "@/pages/GiftcardActivateForm/components/SpkLogo";

export default {
  components: {
    SellerLogo,
    SpkLogo
  },
  methods: {
    selectSupplier(event) {
      const selectedValue = event.target.value;

      const selectedSupplier = this.$store.getters.suppliers.find(supplier => supplier.xfarp == selectedValue );

      if (selectedSupplier) {
        this.activation.supplier_id = selectedSupplier.xfarp;
        this.activation.invoice_number = '';
        this.activation.ocr_number = '';
        this.invoiceNumberType = selectedSupplier.invtn;
        this.rutRot = selectedSupplier.rrs;
        this.supplierName = selectedSupplier.name;

        this.$store.dispatch(RESET_OCR_NUMBER_CHECK);
        this.$store.dispatch(FETCH_XDVA, this.activation.supplier_id).then(() => {
        })
      }
    },
    calculateAmount() {
      let params = {};
      params['total_invoice_amount'] = this.activation.amount;
      params['giftcard_code'] = this.activation.giftcard_code;
      if (this.activation.amount
          && this.activation.giftcard_code
          && (this.activation.invoice_number.length > 1 || this.activation.ocr_number.length > 1)
          && this.activation.giftcard_code.length == 10) {
        this.$store.dispatch(MAKE_GIFTCARD_CALCULATION, params);
      }
    },
    ocrNumberCheckMethod() {
      if (this.activation.ocr_number !== '') {
        this.$store.dispatch(CHECK_OCR_NUMBER, this.activation.ocr_number).then(() => {

       });
      }
    },
    personalNumberCheckMethod() {
      if (this.activation.personal_number !== '') {
        this.$store.dispatch(CHECK_PERSONAL_NUMBER, this.activation.personal_number).then(() => {
        });
      }
    },
    setApproveAgreement() {
      if (this.agreed == true) {
        this.agreed = false;
      } else {
        this.agreed = true;
      }
    },
    showAgreementModal() {
      this.$modal.show(agreement, {}, {
        width: '90%',
        maxWidth: 500,
        adaptive: true,
        height: 'auto',
        minHeight: 400,
        scrollable: true
      });
    },
    sendForm() {
      this.submitted = true;
      //this.emptyFlag = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (this.agreed === false) {
        return;
      }

      if (this.$store.getters.ocrNumberCheck === false
          && this.$store.getters.ocrCheckType == 1
          && this.$store.getters.invoiceNumberType == 1) {
        this.$scrollTo(this.$refs.ocr_number);
        return;
      }

      let params = {};

      if (this.giftcard === null) {
        return;
      }

      params['first_name'] = this.activation.first_name;
      params['last_name'] = this.activation.last_name;
      params['email'] = this.activation.email;
      params['total_invoice_amount'] = this.activation.amount;
      params['personal_number'] = this.activation.personal_number;
      params['invoice_number'] = this.activation.invoice_number;
      params['ocr_number'] = this.activation.ocr_number;
      params['giftcard_code'] = this.activation.giftcard_code;
      params['supplier_id'] = this.activation.supplier_id;
      
      this.$store.dispatch(SET_ACTIVATING_PROGRESS, true).then(() => {
        if (this.giftcardMessageSuccess !== null) {
          this.$store.dispatch(CREATE_GIFTCARD_ACTIVATION, params).then(() => {
            this.$store.dispatch(SET_ACTIVATING_PROGRESS, false);
          });
        }
      });
    }
  },
  data() {
    return {
      activation: {
        giftcard_code: '',
        personal_number: '',
        invoice_number: '',
        ocr_number: '',
        amount: '',
        first_name: '',
        last_name: '',
        email: '',
        supplier_id: null
      },
      activationButtonDisable: false,
      giftcardActivation: {
          activation_amount_value: 0,
          giftcard_left_amount_value: 0,
          giftcard_new_left_amount_value: 0,
          invoice_amount_left_to_pay_value: 0,
          total_invoice_amount_value: 0,
          giftcard_new_left_amount_decimal: 0
      },
      giftcardCalculation: {
        activation_amount: 0,
        giftcard_left_amount: 0,
        giftcard_new_left_amount: 0,
        invoice_amount_left_to_pay: 0,
        total_invoice_amount: 0
      },
      submitted: false,
      agreed: false,
      success: false,
      giftcardExists: false,
      giftcardMessageSuccess: null,
      giftcardMessageError: null,
      giftcardTest: null,
      giftcardTestShort: null,
      goodAttempt: false,
      invoiceNumberType: null,
      invoiceNumberTypeOCR: INVOICE_NUMBER_TYPE_OCR,
      invoiceNumberTypeNumber: INVOICE_NUMBER_TYPE_NUMBER,
      emptyFlag: false,
      rutRot: false,
      supplierName: null
    }
  },
  validations: {
    activation: {
      email: {
        email, required
      },
      first_name: {
        required
      },
      last_name: {
        required
      },
      giftcard_code: {
        required
      },
      personal_number: {
        required: requiredIf(function() {
          return this.rutRot == true;
        })
      },
      amount: {
        required,
        numeric
      },
      invoice_number: {
        required: requiredIf(function() {
          return this.invoiceNumberType == this.invoiceNumberTypeNumber;
        })
      },
      ocr_number: {
        required: requiredIf(function() {
          return this.invoiceNumberType == this.invoiceNumberTypeOCR;
        })
      },
      supplier_id: {
        required: requiredIf(function() {
          return this.$store.getters.isSuperSeller == 1;
        })
      }
    }
  },
  computed: {
    validationColor() {
      if (this.$store.getters.ocrCheckType == 0) {
        return '#ff8c00'
      } else {
        return '#e63027';
      }
    },
    activatingProgress() {
      return this.$store.getters.activatingProgress;
    },
    isSuperSeller() {
      return this.$store.getters.isSuperSeller;
    },
    bankNumber() {
      return this.$store.getters.bankNumber;
    },
    suppliers() {
      return this.$store.getters.suppliers;
    },
    buttonColor() {
      return this.$store.getters.buttonColor;
    },
    buttonTextColor() {
      return this.$store.getters.buttonTextColor;
    },
    checkboxBorderColor() {
      return this.$store.getters.checkboxBorderColor;
    },
    showSellerLogo() {
      return this.$store.getters.showSellerLogo;
    },
    ocrNumberCheck() {
      return this.$store.getters.ocrNumberCheck;
    },
    personalNumberCheck() {
      return this.$store.getters.personalNumberCheck;
    }
  },
  watch: {
    '$store.state.pluginSettings.pluginSettings': function () {

      let isSuperSeller = 0;

      if ('giftcard_seller' in this.$store.state.pluginSettings.pluginSettings
          && 'is_super_seller' in this.$store.state.pluginSettings.pluginSettings.giftcard_seller) {
          isSuperSeller = this.$store.state.pluginSettings.pluginSettings.giftcard_seller.is_super_seller;
      }

      if (isSuperSeller == 0) {
        if ('giftcard_seller' in this.$store.state.pluginSettings.pluginSettings
          && 'invoice_number_type' in this.$store.state.pluginSettings.pluginSettings.giftcard_seller) {
          this.invoiceNumberType  = this.$store.state.pluginSettings.pluginSettings.giftcard_seller.invoice_number_type;
        }

        if ('giftcard_seller' in this.$store.state.pluginSettings.pluginSettings
        && 'rut_rot' in this.$store.state.pluginSettings.pluginSettings.giftcard_seller) {
          this.rutRot = this.$store.state.pluginSettings.pluginSettings.giftcard_seller.bank_number;
        }
      }
    },
    '$store.state.giftcards.giftcardError': function () {
      this.giftcardMessageError = this.$store.state.giftcards.giftcardError;
    },
    '$store.state.giftcards.giftcardActivation': function () {
      if ('total_invoice_amount_value' in this.$store.state.giftcards.giftcardActivation) {
        this.success = true;
        this.giftcardActivation = this.$store.state.giftcards.giftcardActivation;
      } else {
        this.giftcardActivation = {
          activation_amount_value: 0,
          giftcard_left_amount_value: 0,
          giftcard_new_left_amount_value: 0,
          invoice_amount_left_to_pay_value: 0,
          total_invoice_amount_value: 0
        },
        this.success = false;
      }
    },
    '$store.state.giftcards.giftcardCalculation': function () {
      if ('total_invoice_amount' in this.$store.state.giftcards.giftcardCalculation) {
        this.giftcardCalculation = this.$store.state.giftcards.giftcardCalculation;
      } else {
        this.giftcardCalculation = {
          activation_amount: 0,
          giftcard_left_amount: 0,
          giftcard_new_left_amount: 0,
          invoice_amount_left_to_pay: 0,
          total_invoice_amount: 0
        }
      }
    },
    '$store.state.giftcards.giftcard': function () {
       if (this.$store.state.giftcards.giftcard !== null
           && 'valid_date' in this.$store.state.giftcards.giftcard
           && 'amount_left_value' in this.$store.state.giftcards.giftcard) {
         this.giftcardMessageSuccess = 'Gäller till '
             + this.$store.state.giftcards.giftcard.valid_date
             + '. Återstående värde är ' + this.$store.state.giftcards.giftcard.amount_left_value + ' kr.';

         this.giftcardMessageError = null;
         this.gifcardExists = true;
         if ('is_test' in this.$store.state.giftcards.giftcard) {
           if (this.$store.state.giftcards.giftcard.is_test == 1) {
              this.giftcardTest = 'ENDAST TEST, INGEN BETALNING SKICKAS.';
              this.giftcardTestShort = ' TEST';
           }
         }
         this.calculateAmount();
       } else {

         if (this.$store.state.giftcards.giftcardError) {
          this.giftcardMessageError = this.$store.state.giftcards.giftcardError;
         } else {
          this.giftcardMessageError = 'Presentkortet är inte giltigt.'
         }

         this.giftcardMessageSuccess = null;
         this.gifcardExists = false;
       }

    },
    'activation.giftcard_code': function () {
      if (this.activation.giftcard_code.length == 10) {
        this.$store.dispatch(FETCH_GIFTCARD_BY_CODE, this.activation.giftcard_code);
        this.goodAttempt = true;
      } else {
        if (this.goodAttempt == true) {
          this.giftcardMessageError = 'Presentkortet är inte giltigt.';
          this.$store.dispatch(SET_GIFTCARD_ERROR, this.giftcardMessageError);
        }
        this.giftcardMessageSuccess = null;
      }
    },
    'activation.amount': function () {
      this.calculateAmount();
    }
  },
  
}
</script>