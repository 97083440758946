<template>
  <div class="modal-filter" id="modalFilter">
    <div class="modal-filter-inner">
      <div class="modal-filter-body" style="font-size: 14px !important;">
        <h2>Användarvillkor presentkort</h2>
        <div>
          <h3>{{ giftcardSellerName }}</h3>
          <p>
            Hanteringen och betalflödet hanteras av SmartaPresentkort™ (drivs av About Time Tools AB, orgnr 559347-0551) och vi samlar in de personuppgifter som är nödvändiga för att genomföra ett köp, hantera och lösa in presentkortet. Uppgifterna delas med {{ giftcardSellerName }}. Uppgifterna kan också komma att användas för marknadskommunikation och verksamhetsutveckling. Under dataskyddsförordningen har du rätt att begära ett utdrag av dina uppgifter, rätta dem eller få dem raderade. Vill du komma i kontakt med oss angående dina personuppgifter ska du i första hand vända dig till företaget som är angiven på presentkortet och de kontaktuppgifter som är angivna där. Du har också rätt att framföra eventuella klagomål till Datainspektionen.
          </p>
          <p>
            Du kan läsa mer om hur dina uppgifter behandlas i våra
            <a v-bind:style="{ color: buttonColor + ' !important'}"
               href="https://www.smartapresentkort.se/anvandarvillkor-presentkort/" target="_blank">
              användarvillkor
            </a>.
          </p>
        </div>
        <button v-bind:style="{ backgroundColor: buttonColor + ' !important', color: buttonTextColor + ' !important'}" type="button" class="btn-bokahem btn-bokahem-primary-button text-button-color font-weight-semi-bokahem btn-bokahem-lg"
                @click="closeModal()">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit('close');
    }
  },
  computed: {
    giftcardSellerName() {
      return this.$store.getters.giftcardSellerName;
    },
    buttonColor() {
      return this.$store.getters.buttonColor;
    },
    buttonTextColor() {
      return this.$store.getters.buttonTextColor;
    },
  }
}
</script>