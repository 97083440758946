import actions from '@/store/modules/giftcards/actions';
import mutations from '@/store/modules/giftcards/mutations';
import getters from '@/store/modules/giftcards/getters';
import state from '@/store/modules/giftcards/state';

const giftcards = {
   actions: actions,
   mutations: mutations,
   getters: getters,
   state: state
}

export default giftcards;