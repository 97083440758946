<template>
  <div class="row-bokahem mt-1 mb-1" v-if="showSellerLogo === 1">
    <div class="col-bokahem-12 m-auto text-center">
      <img :src="logo" class="smartapresentkort-logo" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    logo() {
      return this.$store.getters.logo;
    },
    showSellerLogo() {
      return this.$store.getters.showSellerLogo;
    }
  },
}
</script>