import axios from "axios";

import { NUMBER_CHECK_API_URL } from "@/core/config";
import { NUMBER_CHECK_API_AUTHORIZATION } from "@/core/config";

export const CheckPersonalNumberService = {
    get(personalNumber) {
        let query = 'personalnumber/validate?personalnumber=' + personalNumber;
        return axios.get(query, {
            baseURL: NUMBER_CHECK_API_URL,
            headers: {
                'Authorization': NUMBER_CHECK_API_AUTHORIZATION
            }
        });
    },
};

export const CheckOcrNumberService = {
    get(ocrNumber) {
        let query = 'ocrnumber/validate?ocrnumber=' + ocrNumber;
        return axios.get(query, {
            baseURL: NUMBER_CHECK_API_URL,
            headers: {
                'Authorization': NUMBER_CHECK_API_AUTHORIZATION
            }
        });
    },
};

export default {
    CheckOcrNumberService,
    CheckPersonalNumberService
};