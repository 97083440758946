import {INVOICE_NUMBER_TYPE_OCR, OCR_CHECK_TYPE} from "@/store/constants.type";

const getters = {
    pluginSettings(state) {
        return state.pluginSettings;
    },
    origin(state) {
        if ('origin' in state.pluginSettings) {
            return state.pluginSettings.origin;
        } else {
            return null;
        }
    },
    invoiceNumberType(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'invoice_number_type' in state.pluginSettings.giftcard_seller) {
            return state.pluginSettings.giftcard_seller.invoice_number_type;
        } else {
            return INVOICE_NUMBER_TYPE_OCR;
        }
    },
    ocrCheckType(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'ocr_check_type' in state.pluginSettings.giftcard_seller) {
            return state.pluginSettings.giftcard_seller.ocr_check_type;
        } else {
            return OCR_CHECK_TYPE;
        }
    },
    suppliers(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'suppliers' in state.pluginSettings.giftcard_seller) {
                return state.pluginSettings.giftcard_seller.suppliers;
        } else {
            return [];
        }
    },
    isSuperSeller(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'is_super_seller' in state.pluginSettings.giftcard_seller) {
                return state.pluginSettings.giftcard_seller.is_super_seller;
        } else {
            return 0;
        }
    },
    bankNumber(state) {
        if ('xdva' in state.xdva) {
            return state.xdva.xdva;
        } else {
            if ('giftcard_seller' in state.pluginSettings
                && 'xdva' in state.pluginSettings.giftcard_seller) {
                return state.pluginSettings.giftcard_seller.xdva;
            } else {
                return null;
            }
        }
    },
    buttonColor(state) {
        if ('setting_button_color' in state.pluginSettings) {
            return state.pluginSettings.setting_button_color;
        } else {
            return null;
        }
    },
    buttonTextColor(state) {
        if ('setting_button_text_color' in state.pluginSettings) {
            return state.pluginSettings.setting_button_text_color;
        } else {
            return null;
        }
    },
    checkboxBorderColor(state) {
        if ('setting_checkbox_border_color' in state.pluginSettings) {
            return state.pluginSettings.setting_checkbox_border_color;
        } else {
            return null;
        }
    },
    showSellerLogo(state) {
        if ('setting_show_seller_logo' in state.pluginSettings) {
            return state.pluginSettings.setting_show_seller_logo;
        } else {
            return null;
        }
    },
    logo(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'logo_link' in state.pluginSettings.giftcard_seller) {
            return state.pluginSettings.giftcard_seller.logo_link;
        } else {
            return null;
        }
    },
    rutRot(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'rut_rot_service' in state.pluginSettings.giftcard_seller) {
            if (state.pluginSettings.giftcard_seller.rut_rot_service == 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
    headingTitle(state) {
        if ('heading_title_activation' in state.pluginSettings) {
            return state.pluginSettings.heading_title_activation;
        } else {
            return null;
        }
    },
    giftcardSellerName(state) {
        if ('giftcard_seller' in state.pluginSettings
            && 'name' in state.pluginSettings.giftcard_seller) {
            return state.pluginSettings.giftcard_seller.name;
        } else {
            return null;
        }
    }
}

export default getters;