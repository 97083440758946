import {CHECK_PERSONAL_NUMBER} from "@/store/actions.type";
import {CHECK_OCR_NUMBER} from "@/store/actions.type";

import {SET_PERSONAL_NUMBER_CHECK} from "@/store/mutations.type";
import {SET_OCR_NUMBER_CHECK} from "@/store/mutations.type";
import {RESET_OCR_NUMBER_CHECK} from "@/store/mutations.type";

import { CheckPersonalNumberService, CheckOcrNumberService } from "@/core/services/externalApi";

const actions = {
    [CHECK_PERSONAL_NUMBER]({ commit }, params) {
        return CheckPersonalNumberService.get(params)
            .then(({ data }) => {
                commit(SET_PERSONAL_NUMBER_CHECK, data.data);
            })
            .catch(error => {
                commit(SET_PERSONAL_NUMBER_CHECK, false);
                return error;
            });
    },
    [CHECK_OCR_NUMBER]({ commit }, params) {
        return CheckOcrNumberService.get(params)
            .then(({ data }) => {
                commit(SET_OCR_NUMBER_CHECK, data.data);
            })
            .catch(error => {
                commit(SET_OCR_NUMBER_CHECK, false);
                return error;
            });
    },
    [RESET_OCR_NUMBER_CHECK]({ commit }) {
        commit(RESET_OCR_NUMBER_CHECK);
    },
}

export default actions;