import {FETCH_PLUGIN_SETTINGS} from "@/store/actions.type";
import {FETCH_XDVA} from "@/store/actions.type";
import {SET_PLUGIN_SETTINGS} from "@/store/mutations.type";
import {SET_XDVA} from "@/store/mutations.type";
import {PluginSettingsService} from "@/core/services/api";
import {XdvaService} from "@/core/services/api";

const actions = {
    [FETCH_PLUGIN_SETTINGS]({ commit }) {
        return PluginSettingsService.get()
            .then(({ data }) => {
                commit(SET_PLUGIN_SETTINGS, data.data);
            })
            .catch(error => {
                commit(SET_PLUGIN_SETTINGS, []);
                return error;
            });
    },
    [FETCH_XDVA]({ commit }, xfarp) {
        return XdvaService.get(xfarp)
            .then(({ data }) => {
                commit(SET_XDVA, data.data);
            })
            .catch(error => {
                commit(SET_XDVA, null);
                return error;
            });
    },
}

export default actions;