import {SET_PLUGIN_SETTINGS, SET_XDVA} from "@/store/mutations.type";

const mutations = {
    [SET_PLUGIN_SETTINGS](state, pluginSettings) {
        state.pluginSettings = pluginSettings;
    },
    [SET_XDVA](state, xdva) {
        state.xdva = xdva;
    },
}

export default mutations;