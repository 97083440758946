import state from '@/store/modules/pluginSettings/state';
import getters from '@/store/modules/pluginSettings/getters';
import actions from '@/store/modules/pluginSettings/actions';
import mutations from '@/store/modules/pluginSettings/mutations';

const pluginSettings = {
   state: state,
   getters: getters,
   actions: actions,
   mutations: mutations
}

export default pluginSettings;