import {SET_GIFTCARD_ACTIVATION} from "@/store/mutations.type";
import {SET_GIFTCARD_CALCULATION} from "@/store/mutations.type";
import {SET_ACTIVATING_PROGRESS} from "@/store/mutations.type";
import {SET_GIFTCARD,SET_GIFTCARD_ERROR} from "@/store/mutations.type";

const mutations = {
    [SET_GIFTCARD_ACTIVATION](state, giftcardActivation) {
        state.giftcardActivation = giftcardActivation;
    },
    [SET_GIFTCARD_CALCULATION](state, giftcardCalculation) {
        state.giftcardCalculation = giftcardCalculation;
    },
    [SET_GIFTCARD](state, giftcard) {
        state.giftcard = giftcard;
    },
    [SET_GIFTCARD_ERROR](state, giftcardError) {
        state.giftcardError = giftcardError;
    },
    [SET_ACTIVATING_PROGRESS](state, activatingProgress) {
        state.activatingProgress = activatingProgress;
    },
}

export default mutations;