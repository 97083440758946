const getters = {
    giftcardActivation(state) {
        return state.giftcardActivation;
    },
    giftcardCalculation(state) {
        return state.giftcardCalculation;
    },
    giftcard(state) {
        return state.giftcard;
    },
    giftcardError(state) {
        return state.giftcardError;
    },
    activatingProgress(state) {
        return state.activatingProgress;
    }
}

export default getters;