import GiftcardActivateForm from "@/pages/GiftcardActivateForm";

const routes = [
    {
        path: '/',
        name: 'GiftcardActivateForm',
        component: GiftcardActivateForm
    },
]

export default routes;