import actions from '@/store/modules/checkNumbers/actions';
import mutations from '@/store/modules/checkNumbers/mutations';
import getters from '@/store/modules/checkNumbers/getters';
import state from '@/store/modules/checkNumbers/state';

const giftcards = {
   actions: actions,
   mutations: mutations,
   getters: getters,
   state: state
}

export default giftcards;