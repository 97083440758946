import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "@/core/config";

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
        Vue.axios.defaults.headers.common['placement'] = document.URL;
    },
    get(resource, slug = "") {
        return Vue.axios.get(`${resource}${slug}`);
    },
    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    }
};

export default ApiService;

/**
 *
 * @type {{get(): *}}
 */
export const PluginSettingsService = {
    get() {
        return ApiService.get('giftcardsellers/activation-plugin', '');
    }
};

/**
 *
 * @type {{get(): *}}
 */
export const CreateGiftcardActivationService = {
    get(params) {
        return ApiService.post('giftcards/activate', params);
    }
};

/**
 *
 * @type {{get(): *}}
 */
export const XdvaService = {
    get(supplierId) {
        let query = '?xfarp=' + supplierId;
        return ApiService.get('giftcardsellers/xdva', query);
    }
};


/**
 *
 * @type {{get(): *}}
 */
export const GiftcardCalculationService = {
    get(params) {
        return ApiService.post('giftcards/calculate', params);
    }
};

/**
 *
 * @type {{get(*): *}}
 */
export const FetchGiftcardByCodeService = {
    get(giftcardCode) {
        let query = '?code=' + giftcardCode
        return ApiService.get('giftcards/find', query);
    }
};
