import {FETCH_GIFTCARD_BY_CODE} from "@/store/actions.type";
import {CREATE_GIFTCARD_ACTIVATION} from "@/store/actions.type";
import {MAKE_GIFTCARD_CALCULATION} from "@/store/actions.type";
import {SET_GIFTCARD_ACTIVATION} from "@/store/mutations.type";
import {SET_GIFTCARD_CALCULATION} from "@/store/mutations.type";
import {SET_ACTIVATING_PROGRESS} from "@/store/mutations.type";
import {SET_GIFTCARD,SET_GIFTCARD_ERROR} from "@/store/mutations.type";

import {CreateGiftcardActivationService} from "@/core/services/api";
import {GiftcardCalculationService} from "@/core/services/api";
import {FetchGiftcardByCodeService} from "@/core/services/api";

const actions = {
    [MAKE_GIFTCARD_CALCULATION]({ commit }, params) {
        return GiftcardCalculationService.get(params)
            .then(({ data }) => {
                commit(SET_GIFTCARD_CALCULATION, data);
            })
            .catch(error => {
                commit(SET_GIFTCARD_CALCULATION, null);
                return error;
            });
    },
    [CREATE_GIFTCARD_ACTIVATION]({ commit }, params) {
        return CreateGiftcardActivationService.get(params)
            .then(({ data }) => {
                commit(SET_GIFTCARD_ACTIVATION, data);
            })
            .catch(error => {
                commit(SET_GIFTCARD_ACTIVATION, null);
                return error;
            });
    },
    [FETCH_GIFTCARD_BY_CODE]({ commit }, params) {
        return FetchGiftcardByCodeService.get(params)
            .then(({ data }) => {
                commit(SET_GIFTCARD, data);
                commit(SET_GIFTCARD_ERROR, null);
            })
            .catch(error => {
                commit(SET_GIFTCARD_ERROR, error.response.data.message);
                commit(SET_GIFTCARD, null);
                return error;
            });
    },
    [SET_GIFTCARD_ERROR]({commit}, params) {
        commit(SET_GIFTCARD_ERROR, params);
        commit(SET_GIFTCARD, null);
    },
    [SET_ACTIVATING_PROGRESS]({commit}, activatingProgress) {
        commit(SET_ACTIVATING_PROGRESS, activatingProgress);
    }
}

export default actions;