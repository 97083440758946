import { SET_PERSONAL_NUMBER_CHECK } from "@/store/mutations.type";
import { SET_OCR_NUMBER_CHECK } from "@/store/mutations.type";
import { RESET_OCR_NUMBER_CHECK } from "@/store/mutations.type";

const mutations = {
    [SET_PERSONAL_NUMBER_CHECK](state, personalNumberCheck) {
        state.personalNumberCheck = personalNumberCheck;
    },
    [SET_OCR_NUMBER_CHECK](state, ocrNumberCheck) {
        state.ocrNumberCheck = ocrNumberCheck;
    },
    [RESET_OCR_NUMBER_CHECK](state) {
        state.ocrNumberCheck = null;
    }
}

export default mutations;