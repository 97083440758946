<template>
  <div id="spk-plugin-activation">
    <div>
      <main>
        <div class="container-bokahem mt-2" v-if="headingTitle">
          <div class="calendar-bokahem">
            <div class="calendar-bokahem-body">
              <h2>{{ headingTitle }}</h2>
            </div>
          </div>
        </div>

        <GiftcardActivateForm></GiftcardActivateForm>
      </main>
      <modals-container/>
    </div>
  </div>
</template>

<script>

  import './assets/scss/style.scss';
  import './assets/custom-svg/sprites.svg';

  import GiftcardActivateForm from "@/pages/GiftcardActivateForm";

  import { FETCH_PLUGIN_SETTINGS } from "@/store/actions.type";

  export default {
    name: 'App',
    mounted() {
      this.$store.dispatch(FETCH_PLUGIN_SETTINGS);

      setTimeout(function () {
        Array.from(document.querySelectorAll('.info-block-bokahem-input.form-control')).forEach(function(el) {
          el.classList.remove('form-control');
        });

        Array.from(document.querySelectorAll('.info-input-bokahem-holder.form-group')).forEach(function(el) {
          el.classList.remove('form-group');
        });
      }, 150);
    },
    components: {
      GiftcardActivateForm
    },
    computed: {
      headingTitle() {
        return this.$store.getters.headingTitle;
      }
    }
  }

</script>
